import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { Snackbar, Alert } from "@mui/material";
import './EditServicePage.scss';

const EditServicePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState(null);
  const [formData, setFormData] = useState({
    serviceName: '',
    description: '',
    timeDuration: '',
    review: ''
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchService = async () => {
      try {
        const response = await fetch(`https://salon.hasthiya.org/service/get/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setService(data.data);
          setFormData({
            serviceName: data.data.serviceName,
            description: data.data.description,
            timeDuration: data.data.timeDuration,
            review: data.data.review
          });
        } else {
          console.error('Failed to fetch service details');
        }
      } catch (error) {
        console.error('Error fetching service details:', error);
      }
    };

    fetchService();
  }, [id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`https://salon.hasthiya.org/service/update/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });
      if (response.ok) {
        setSnackbarMessage('Service updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setTimeout(() => {
          navigate('/services', { state: { reload: true } });
        }, 3000);
      } else {
        setSnackbarMessage('Failed to update service');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Error updating service');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <div className="edit-service-page">
      <Sidebar />
      <div className="editContainer">
      <Navbar />
      <div className="edit-service-page__content">
        <h1>Edit Service</h1>
        {service ? (
          <form onSubmit={handleSubmit}>
            <label>Service Name:</label>
            <input type="text" name="serviceName" value={formData.serviceName} onChange={handleChange} />
            <label>Description:</label>
            <input type="text" name="description" value={formData.description} onChange={handleChange} />
            <label>Time Duration:</label>
            <input type="text" name="timeDuration" value={formData.timeDuration} onChange={handleChange} />
            <label>Review:</label>
            <input type="text" name="review" value={formData.review} onChange={handleChange} />
            <button type="submit">Save</button>
          </form>
        ) : (
          <p>Loading...</p>
        )}
      </div>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "center", horizontal: "center" }}
        sx={{
          '& .MuiSnackbarContent-root': {
            minWidth: '900px',
            fontSize: '1.5rem', // Increase font size
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px', // Increase padding for a bigger snackbar
          }
        }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px', // Increase padding for a bigger alert
            fontSize: '1.5rem', // Increase font size
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </div>
    </div>
  );
};

export default EditServicePage;
