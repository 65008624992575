import React, { useState, useEffect } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { Snackbar, Alert, Button } from "@mui/material"; // Import Button component

const ServicesTab = ({ services }) => {
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);
  const [token, setToken] = useState(sessionStorage.getItem("token"));
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [successAlertOpen, setSuccessAlertOpen] = useState(false);
  const [errorAlertOpen, setErrorAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [deletingServiceId, setDeletingServiceId] = useState(null); // Track which service is being deleted

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [navigate, token]);

  useEffect(() => {
    if (location.state && location.state.reload) {
      setReload(true);
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state, navigate]);

  useEffect(() => {
    if (reload) {
      window.location.reload();
    }
  }, [reload]);

  useEffect(() => {
    setData(services);
  }, [services]);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://salon.hasthiya.org/service/delete/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        setData(data.filter((item) => item.id !== id));
        setAlertMessage('Service deleted successfully');
        setSuccessAlertOpen(true);
        setReload(true);
      } else {
        setAlertMessage('Failed to delete service');
        setErrorAlertOpen(true);
      }
    } catch (error) {
      setAlertMessage('Error deleting service');
      setErrorAlertOpen(true);
    } finally {
      setDeletingServiceId(null); // Reset deletingServiceId after deletion attempt
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/edit/${params.row.id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">Edit</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => {
                setDeletingServiceId(params.row.id); // Set deletingServiceId when delete button is clicked
                setDeleteAlertOpen(true);
              }}
            >
              Delete
            </div>
            <Link to="/add" style={{ textDecoration: "none" }}>
              <div className="addButton">Add</div>
            </Link>
          </div>
        );
      },
    },
  ];

  const columns = [
    { field: 'serviceId', headerName: 'Service ID', width: 150 },
    { field: 'serviceName', headerName: 'Service Name', width: 200 },
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'timeDuration', headerName: 'Time Duration (minutes)', width: 150 },
    { field: 'review', headerName: 'Review', width: 150 },
    { field: 'createdAt', headerName: 'Created At', width: 200 },
    { field: 'updatedAt', headerName: 'Updated At', width: 200 },
    ...actionColumn
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Services
        <Link to="/add" className="link">
          Add New
        </Link>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
      <Snackbar open={deleteAlertOpen} autoHideDuration={6000} onClose={() => setDeleteAlertOpen(false)}>
        <Alert onClose={() => setDeleteAlertOpen(false)} severity="warning">
          Are you sure you want to delete this service?
          <Button color="inherit" size="small" onClick={() => handleDelete(deletingServiceId)}>Yes</Button>
        </Alert>
      </Snackbar>
      <Snackbar open={successAlertOpen} autoHideDuration={6000} onClose={() => setSuccessAlertOpen(false)}>
        <Alert onClose={() => setSuccessAlertOpen(false)} severity="success">
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={errorAlertOpen} autoHideDuration={6000} onClose={() => setErrorAlertOpen(false)}>
        <Alert onClose={() => setErrorAlertOpen(false)} severity="error">
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

ServicesTab.propTypes = {
  services: PropTypes.array.isRequired
};

export default ServicesTab;


