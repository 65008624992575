import React from "react";
import PropTypes from 'prop-types';
import "./ServicesList.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import ServicesTab from "../../components/ServisesTab/ServicesTab";

const ServicesList = ({ services }) => {
  console.log("Services:", services);

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <ServicesTab services={services || []} /> {/* Provide a default empty array */}
      </div>
    </div>
  );
};

ServicesList.propTypes = {
  services: PropTypes.array.isRequired
};

export default ServicesList;
