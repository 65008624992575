import React, { useState } from 'react';
import './Catogories.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddCategoryForm from '../AddCategoryForm/AddCategoryForm';

function Catogories() {
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const addCategory = (newCategory) => {
    setCategories([...categories, newCategory]);
    setShowForm(false); // Hide the form after adding a category
  };

  return (
    <div className='addc'>
      <Sidebar />
      <div className='addContainer'>
        <Navbar />
        <div className='add-top'>
          <h3>Add new categories</h3>
          <div className='addbutton' onClick={toggleForm}>
            Add New
          </div>
        </div>
        <div className={`form-popup ${showForm ? 'show' : ''}`}>
          <AddCategoryForm onAddCategory={addCategory} />
        </div>
        <div className='card'>
          {categories.map(category => (
            <Card key={category.name} sx={{ maxWidth: 300 }}>
              <CardMedia
                component="img"
                alt={category.name}
                height="140"
                image={category.image || '../../assets/images/default-image.jpg'}
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {category.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {category.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Edit</Button>
                <Button size="small">Delete</Button>
              </CardActions>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Catogories;
