import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import ServicesList from "./pages/SevicesList/ServicesList.jsx"; // Corrected import path
import Catogories from "./pages/Catogories/Catogories.jsx";

//import Sidebar from "./components/sidebar/Sidebar.jsx";
import Notification from "./pages/Notification/Notification.jsx";
import Appointments from "./pages/Appointments.jsx";
import CancelledAppointments from "./pages/CancelledAppointments.jsx";
import Hair from "./pages/Hair1/Hair1.jsx";
import Price from "./pages/Price/Price.jsx";
import AvailabletimeLIst from "./pages/Availabletimeist/AvailabletimeLIst.jsx";
import EditServicePage from "./pages/EditServicePage/EditServicePage.jsx"; // Import EditServicePage component
import ProfileEditPage from "./pages/ProfileEdit/ProfileEditPage.jsx";
import SubcategoryPage from "./pages/SubcategoryPage/SubcategoryPage.jsx";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Fetch services data here
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await fetch("https://salon.hasthiya.org/service/getAll");
      if (response.ok) {
        const responseData = await response.json();
        if (Array.isArray(responseData.data.data)) {
          const servicesWithId = responseData.data.data.map((service) => ({
            ...service,
            id: service.serviceId, // Map serviceId to id
          }));
          setServices(servicesWithId);
        } else {
          console.error("Invalid data format:", responseData);
        }
      } else {
        console.error("Failed to fetch services");
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="notification" element={<Notification />} />
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="users">
            <Route index element={<List />} />
            <Route path=":emp_id" element={<Single />} />
            <Route
              path="new"
              element={<New inputs={userInputs} title="Add New User" />}
            />
          </Route>
          <Route path="services">
            <Route index element={<ServicesList services={services} />} />
            <Route path=":productId" element={<Single />} />
            <Route
              path="new"
              element={<New inputs={productInputs} title="Add New Product" />}
            />
          </Route>
          <Route path="availabletime" element={<AvailabletimeLIst />} />
          <Route path="add" element={<Catogories />} />
          <Route path="appointments" element={<Appointments />} />
          <Route
            path="cancelledAppointments"
            element={<CancelledAppointments />}
          />
          <Route path="Hair1" element={<Hair />} />
          <Route path="Price" element={<Price />} />

          <Route path="edit/:id" element={<EditServicePage />} />

          <Route path="ProfileEditPage" element={<ProfileEditPage />} />
          <Route path="SubcategoryPage" element={<SubcategoryPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
