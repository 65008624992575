import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Appointments.scss'; 
import Sidebar from '../components/sidebar/Sidebar'; 
import Navbar from '../components/navbar/Navbar'; 
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SpaIcon from '@mui/icons-material/Spa';
import FaceIcon from '@mui/icons-material/Face';
import BrushIcon from '@mui/icons-material/Brush';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

function Appointments() {
  const [sortBy, setSortBy] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [confirmBookings, setConfirmBookings] = useState([]);
  const [pendingBookings, setPendingBookings] = useState([]);
  const [showAllBookings, setShowAllBookings] = useState(true);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token"); // Get the token from session storage

  useEffect(() => {
    if (!token) {
      navigate("/login"); // Redirect to login if token is not available
    } else {
      fetchAppointments();
    }
  }, [token, navigate]);

  // Function to fetch appointments
  const fetchAppointments = () => {
    fetch('https://salon.hasthiya.org/booking/getUsersBooking/1', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch appointments');
        }
        return response.json();
      })
      .then(data => {
        setAppointments(data.data);
      })
      .catch(error => console.error('Error fetching appointments:', error));
  };

  // Function to fetch confirm bookings
  const handleFetchConfirmBookings = () => {
    fetch('https://salon.hasthiya.org/booking/getConfirmBooking/1', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch confirm bookings');
        }
        return response.json();
      })
      .then(data => {
        setConfirmBookings(data.data);
        setShowAllBookings(false);
      })
      .catch(error => console.error('Error fetching confirm bookings:', error));
  };

  // Function to fetch pending bookings
  const handleFetchPendingBookings = () => {
    fetch('https://salon.hasthiya.org/booking/getPendingBooking/1', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch pending bookings');
        }
        return response.json();
      })
      .then(data => {
        setPendingBookings(data.data);
        setShowAllBookings(false);
      })
      .catch(error => console.error('Error fetching pending bookings:', error));
  };

  // Function to format date in a readable format
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
  };

  // Function to count appointments by service
  const countAppointmentsByService = (service) => {
    return appointments.filter(appointment => appointment.service === service).length;
  };

  // Function to count total appointments
  const countTotalAppointments = () => {
    return appointments.length;
  };

  return (
    <div className='appointments'>
      <Sidebar />
      <div className='appointmentsContainer'>
        <Navbar />
        <div className='appointments-top'>
          <h3>Appointments List</h3>

          <FormControl variant="outlined" className="sort-by">
            <InputLabel>Sort By</InputLabel>
            <Select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              label="Sort By"
            >
              <MenuItem value="">Id</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="startTime">Start Time</MenuItem>
              <MenuItem value="status">Status</MenuItem>
              <MenuItem value="endTime">End Time</MenuItem>
              <MenuItem value="customer_id">Customer ID</MenuItem>
              <MenuItem value="createdAt">Created At</MenuItem>
              <MenuItem value="updatedAt">Updated At</MenuItem>
              <MenuItem value="emp_id">Employee ID</MenuItem>
              <MenuItem value="serviceId">Service ID</MenuItem>
            </Select>
          </FormControl>
          {/* Button to fetch confirm bookings */}
          <Button variant="contained" onClick={handleFetchConfirmBookings}>Fetch Confirm Bookings</Button>
          {/* Button to fetch pending bookings */}
          <Button variant="contained" onClick={handleFetchPendingBookings}>Fetch Pending Bookings</Button>
          {/* Button to display total bookings */}
          <Button variant="contained" onClick={() => setShowAllBookings(true)}>Total Bookings: {countTotalAppointments()}</Button>
        </div>

        {/* Display Bookings */}
        <div className="appointments-table">
          <h3>{showAllBookings ? 'All Bookings' : (confirmBookings.length > 0 ? 'Confirm Bookings' : 'Pending Bookings')}</h3>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>End Time</TableCell>
                  <TableCell>Customer ID</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Updated At</TableCell>
                  <TableCell>Employee ID</TableCell>
                  <TableCell>Service ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(showAllBookings ? appointments : (confirmBookings.length > 0 ? confirmBookings : pendingBookings)).map((booking) => (
                  <TableRow key={booking.id}>
                    <TableCell>{booking.id}</TableCell>
                    <TableCell>{formatDate(booking.date)}</TableCell>
                    <TableCell>{booking.startTime}</TableCell>
                    <TableCell>
                      {booking.status === 'Done' && <DoneIcon className='done' />}
                      {booking.status === 'Ongoing' && 'Ongoing'}
                      {booking.status === 'Cancel' && <CancelIcon className='cancel' />}
                    </TableCell>
                    <TableCell>{booking.endTime}</TableCell>
                    <TableCell>{booking.customer_id}</TableCell>
                    <TableCell>{formatDate(booking.createdAt)}</TableCell>
                    <TableCell>{formatDate(booking.updatedAt)}</TableCell>
                    <TableCell>{booking.emp_id}</TableCell>
                    <TableCell>{booking.serviceId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Service Summary Cards */}
        <div className='service-summary'>
          <h3>Service Summary</h3>
          <div className='service-cards'>
            {/* Hair Styling */}
            <div className='service-card'>
              <div className='service-icon'>
                <BrushIcon />
              </div>
              <div className='service-content'>
                <h4>Hair Styling</h4>
                <span>{countAppointmentsByService('Hair Styling')}</span>
              </div>
            </div>
            {/* Makeup */}
            <div className='service-card'>
              <div className='service-icon'>
                <FaceIcon />
              </div>
              <div className='service-content'>
                <h4>Makeup</h4>
                <span>{countAppointmentsByService('Makeup')}</span>
              </div>
            </div>
            {/* Nail Art */}
            <div className='service-card'>
              <div className='service-icon'>
                <SpaIcon />
              </div>
              <div className='service-content'>
                <h4>Nail Art</h4>
                <span>{countAppointmentsByService('Nail Art')}</span>
              </div>
            </div>
            {/* Massage */}
            <div className='service-card'>
              <div className='service-icon'>
                <LocalHospitalIcon />
              </div>
              <div className='service-content'>
                <h4>Massage</h4>
                <span>{countAppointmentsByService('Massage')}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appointments;
