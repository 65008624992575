import React, { useState, useEffect } from 'react';
import './SubcategoryPage.scss';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

// Mock function to simulate fetching services from API
const mockFetchServices = () => {
  return new Promise((resolve, reject) => {
    // Simulate API response delay (2 seconds)
    setTimeout(() => {
      const mockServicesData = [
        {
          id: 1,
          name: 'Hair Services',
          subcategories: [
            { id: 1, name: 'Haircut' },
            { id: 2, name: 'Hair Coloring' },
            { id: 3, name: 'Hair Styling' }
          ]
        },
        {
          id: 2,
          name: 'Nail Services',
          subcategories: [
            { id: 4, name: 'Manicure' },
            { id: 5, name: 'Pedicure' }
          ]
        },
        {
            id: 3,
            name: 'Massage Services',
            subcategories: [
              { id: 6, name: 'Swedish Massage' },
              { id: 7, name: 'Deep Tissue Massage' }
            ]
          },
          {
            id: 4,
            name: 'Facial Services',
            subcategories: [
              { id: 8, name: 'Basic Facial' },
              { id: 9, name: 'Acne Treatment Facial' }
            ]
          },
        // Add more mock data if needed
      ];
      resolve(mockServicesData);
    }, 2000); // Simulate 2 seconds delay
  });
};

const SubcategoryPage = () => {
  // State for storing services data
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to fetch services data from backend
  const fetchServicesData = async () => {
    try {
      const servicesData = await mockFetchServices(); // Call the mockFetchServices function
      setServices(servicesData); // Set services data to state
      setLoading(false); // Set loading state to false
    } catch (error) {
      console.error('Error fetching services:', error);
      setLoading(false); // Set loading state to false even if there's an error
    }
  };

  useEffect(() => {
    fetchServicesData(); // Fetch services data when component mounts
  }, []);

  // Function to add a new subcategory for a service
  const addSubcategory = (serviceId) => {
    // Add your logic to add a new subcategory here
    console.log(`Add new subcategory for service ${serviceId}`);
  };

  // Function to delete a subcategory
  const deleteSubcategory = (serviceId, subcategoryId) => {
    // Add your logic to delete the subcategory here
    console.log(`Delete subcategory ${subcategoryId} from service ${serviceId}`);
  };

  return (
    <div className="subcategory-page">
      <Sidebar />
      <div className="main-content">
        <Navbar />
        <h1 style={{ color: '#5f54da' }}>Subcategories</h1>
        <br />
        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="services-list">
            {services.map(service => (
              <div key={service.id} className="service-item">
                <div className="service-header">
                  {/* Use Link to navigate to Hair1 page */}
                  <Link to="/Hair1" style={{ textDecoration: 'none' }}>
                    <h2>{service.name}</h2>
                  </Link>
                  <button onClick={() => addSubcategory(service.id)}>Add New Subcategory</button>
                </div>
                <ul className="subcategories">
                  {service.subcategories.map(subcategory => (
                    <li key={subcategory.id}>
                      {subcategory.name}
                      <button onClick={() => deleteSubcategory(service.id, subcategory.id)}>Delete</button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default SubcategoryPage;
