import React from 'react';
import { Link } from 'react-router-dom'; // Import Link component
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import './Hair.scss';

function Hair() {
  return (
    <div className="hair-page">
      <Sidebar />
      <div className="hair-page-container">
        <Navbar />
        <div className="hair-page-content">
          <h1>Hair Category</h1>
          <p>This is the hair category page. Here you can find information about various subcategories related to hair services and the available employees.</p>
          
          <h2>Subcategories:</h2>
          <div className="subcategories-list">
            <div className="card">
              <img src="https://mona-rosa.com/cdn/shop/files/19.png?v=1701679696" alt="Haircut" />
              <div className="card-body">
                <h3>Haircut</h3>
                <p>Explore our haircut services for a fresh and stylish look.</p>
              </div>
            </div>
            <div className="card">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQy48C6G6S1RMGYFk8jGn29mNcuK1NblbxXXg&s" alt="Hair Coloring" />
              <div className="card-body">
                <h3>Hair Coloring</h3>
                <p>Transform your hair with our professional hair coloring services.</p>
              </div>
            </div>
            <div className="card">
              <img src="https://d1rezxi0gcra7v.cloudfront.net/zerokaata-studio/wp-content/uploads/2024/01/Bouffant-with-Puff.webp" alt="Hair Styling" />
              <div className="card-body">
                <h3>Hair Styling</h3>
                <p>Elevate your look with our expert hair styling techniques.</p>
              </div>
            </div>
            <div className="card">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXb1TL6hz5lYrJIIWV_hl1UucajiP4u_ynYb9NN_JFJw&s" alt="Hair treatment" />
              <div className="card-body">
                <h3>Hair treatment</h3>
                <p>Many hair problems, such as lack of volume, dull hair, or dandruff, are caused by problems with the scalp.</p>
              </div>
            </div>
            <div className="card">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSay_-erdA0ZDroDYyGwWMftZZHv841gHk_dc4AJat2fA&s" alt="Curls" />
              <div className="card-body">
                <h3>Curls</h3>
                <p>Curls for up to three months. Scalp treatment.</p>
              </div>
            </div>
            <Link to="/add" className="card add-card">
              <div className="add-icon">+</div>
              <div className="card-body">
                <h3>Add Category</h3>
                <p>Click here to add a new category.</p>
              </div>
            </Link>
          </div>
          <h2>Available Employees:</h2>
          <div className="employees-list">
            <div className="employee-card">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7ksiBir5h4JTdGMhogpTXglZWAepwptJcn6kuvkGlWNkKvKlbUFLgHzpJl9wKbPowiUw&usqp=CAU" alt="Employee" />
              <div className="employee-details">
                <h3>Emily</h3>
                <p>Hair Designer</p>
              </div>
            </div>
            <div className="employee-card">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRZ2jgZ5maIjedb8NoqXs830wIRsV_N3It-Og&s" alt="Employee" />
              <div className="employee-details">
                <h3>Michael</h3>
                <p>Hair Cut</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hair;
