import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
//import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EventNoteIcon from "@mui/icons-material/EventNote"; // New icon
import { Link, useNavigate } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import MoneyIcon from "@mui/icons-material/Money";
import { useContext } from "react";

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);

  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem("token");     
    navigate("/"); 
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span className="logo">SalonAdmin</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Employees</span>
            </li>
          </Link>
          <Link to="/services" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Services</span>
            </li>
          </Link>

          <Link to="/notification" style={{ textDecoration: "none" }}>
            {/* <Link to="/availabletime" style={{ textDecoration: "none" }}>
              <li>
                <StoreIcon className="icon" />
                <span>Available Time</span>
              </li>
            </Link> */}
            <Link to="/Appointments" style={{ textDecoration: "none" }}>
              <li>
                <EventNoteIcon className="icon" /> {/* Changed icon */}
                <span>Appointments</span>
              </li>
            </Link>
            <Link to="/Price" style={{ textDecoration: "none" }}>
              <li>
                <MoneyIcon className="icon" />
                <span>Price List</span>
              </li>
            </Link>

            {/* <li>
              <NotificationsNoneIcon className="icon" />
              <span>Notifications</span>
            </li> */}
          </Link>
          <p className="title">SERVICE</p>

          <Link to="/SubcategoryPage" style={{ textDecoration: "none" }}>
            <li>
              <SettingsApplicationsIcon className="icon" />
              <span>Subcategories</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <Link to="/ProfileEditPage" style={{ textDecoration: "none" }}>
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Edit Profile</span>
            </li>
          </Link>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li onClick={handleLogout}>

              <ExitToAppIcon className="icon" />
              <span>Logout</span>
            </li>
          </Link>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
