import React, { useState } from 'react';
import './Notification.css';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

function Notification() {
  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = React.useState('one');

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='notifi'>
      <Sidebar />
      <div className='notifinav'>
        <Navbar />
        <div className='N-title'>
          <h4>Notification</h4>
          <Box sx={{ width: '100%' }}>
            <Tabs 
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab className='tab' value="one" label="Unread" />
              <Tab className='tab' value="two" label="Read" />
              <Stack spacing={2} direction="row">
                {value === 'one' && <Button className='tab-btn' variant="text">Mark all as read</Button>}
                {value === 'two' && <Button className='tab-btn' variant="text">Mark all as delete</Button>}
              </Stack>
            </Tabs>
          </Box>
          <div className='notifi-body'>
            <div className='imgg'>
              <img
                src='https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500'
                alt='user'
              />
            </div>
            <div className='notifi-msg'>
              
            </div>
            <div className='dots' onClick={toggleOptions}>
              <MoreHorizIcon />
              <div className='relative'>
                {showOptions && (
                  <div className='menuItem'>
                    <ul >
                      <li>Delete</li>
                      <li>Check</li>
                      <li>Mark as Read</li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;

