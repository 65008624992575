import React, { useState, useEffect } from "react";
import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { Alert } from '@mui/material';

const AddEmployeeForm = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [servicesID, setServicesID] = useState([]);
  const [phoneNo, setPhoneNo] = useState("");
  const [status, setStatus] = useState("");
  const [workStartTime, setWorkStartTime] = useState("");
  const [workEndTime, setWorkEndTime] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
  }, [token, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !fullName ||
      !email ||
      !password ||
      servicesID.length === 0 ||
      !phoneNo ||
      !workStartTime ||
      !workEndTime
    ) {
      setAlertSeverity('error');
      setAlertMessage('Please fill in all the required fields.');
      setAlertOpen(true);
      return;
    }

    try {
      const requestBody = {
        full_name: fullName,
        email: email,
        password: password,
        services_ID: servicesID,
        phone_No: phoneNo,
        status: status,
        workStartTime: workStartTime,
        workEndTime: workEndTime,
      };

      const response = await fetch(
        "https://salon.hasthiya.org/employee/createEmployee",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Network response was not ok: ${errorMessage}`);
      }

      const responseData = await response.json();
      console.log("Employee added successfully:", responseData);

      setFullName("");
      setEmail("");
      setPassword("");
      setServicesID([]);
      setPhoneNo("");
      setStatus("");
      setWorkStartTime("");
      setWorkEndTime("");

      setAlertSeverity('success');
      setAlertMessage('Employee added successfully');
      setAlertOpen(true);

    } catch (error) {
      console.error("Error adding employee:", error.message);
      setAlertSeverity('error');
      setAlertMessage(`Error adding employee: ${error.message}`);
      setAlertOpen(true);
    }
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />

        <div className="new">
          <div className="newContainer">
            <div className="top">
              Add New Employee Details
              <Link to="/users" className="link" style={{marginLeft:'800px'}} >
                {`<--`}
              </Link>
            </div>
            <div className="bottom">
              <div className="right">
                <form onSubmit={handleSubmit}>
                  <div className="formInput">
                    <label>Full Name<span className="required">*</span></label>
                    <input
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="Enter full name"
                    />
                  </div>

                  <div className="formInput">
                    <label>Services ID<span className="required">*</span></label>
                    <input
                      type="text"
                      value={servicesID}
                      onChange={(e) => setServicesID(e.target.value.split(","))}
                      placeholder="Enter service IDs separated by commas"
                    />
                  </div>

                  <div className="formInput">
                    <label>Email<span className="required">*</span></label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                    />
                  </div>

                  <div className="formInput">
                    <label>Status</label>
                    <input
                      type="text"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      placeholder="Enter status"
                    />
                  </div>

                  <div className="formInput">
                    <label>Password<span className="required">*</span></label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter password"
                    />
                  </div>
                  <div className="formInput">
                    <label>Work Start Time<span className="required">*</span></label>
                    <input
                      type="time"
                      value={workStartTime}
                      onChange={(e) => setWorkStartTime(e.target.value)}
                    />
                  </div>

                  <div className="formInput">
                    <label>Phone Number<span className="required">*</span></label>
                    <input
                      type="text"
                      value={phoneNo}
                      onChange={(e) => setPhoneNo(e.target.value)}
                      placeholder="Enter phone number"
                    />
                  </div>                 

                  <div className="formInput">
                    <label>Work End Time<span className="required">*</span></label>
                    <input
                      type="time"
                      value={workEndTime}
                      onChange={(e) => setWorkEndTime(e.target.value)}
                    />
                  </div>

                  <button type="submit">Add Employee</button>
                  {alertOpen && (
                    <Alert severity={alertSeverity} onClose={() => setAlertOpen(false)}>
                      {alertMessage}
                    </Alert>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployeeForm;
