import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./single.scss";

const EditEmployeePage = () => {
  const { emp_id } = useParams();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [employee, setEmployee] = useState(null);
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    password: "",
    services_ID: "",
    phone_No: "",
    status: "",
    workStartTime: "",
    workEndTime: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  useEffect(() => {
    if (!token) {
      navigate("/");
      return;
    }
  }, [token, navigate]);

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(
          `https://salon.hasthiya.org/employee/get/${emp_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error("Failed to fetch employee details:", errorText);
          setAlert({
            open: true,
            severity: "error",
            message: `Failed to fetch employee details: ${errorText}`,
          });
          return;
        }

        const data = await response.json();
        if (!data.data) {
          setAlert({
            open: true,
            severity: "error",
            message: "Employee not found",
          });
          return;
        }

        setEmployee(data.data);
        console.log(data.data);
        setFormData({
          full_name: data.data.full_name,
          email: data.data.email,
          password: data.data.password,
          services_ID: data.data.services_ID,
          phone_No: data.data.phone_No,
          status: data.data.status,
          workStartTime: data.data.workStartTime,
          workEndTime: data.data.workEndTime,
        });
      } catch (error) {
        console.error("Error fetching employee details:", error);
        setAlert({
          open: true,
          severity: "error",
          message: "Error fetching employee details",
        });
      }
    };

    fetchEmployee();
  }, [emp_id, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://salon.hasthiya.org/employee/update/${emp_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Failed to update employee:", errorText);
        setAlert({
          open: true,
          severity: "error",
          message: `Failed to update employee: ${errorText}`,
        });
        return;
      }

      setAlert({
        open: true,
        severity: "success",
        message: "Employee updated successfully",
      });
      setTimeout(() => {
        navigate("/users", { state: { reload: true } });
      }, 2000);
    } catch (error) {
      console.error("Error updating employee:", error);
      setAlert({
        open: true,
        severity: "error",
        message: "Error updating employee",
      });
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="edit-employee-page__content">
          <h1 className="title">Employee Details</h1>

          {employee ? (
            <form onSubmit={handleSubmit}>
              <div>
                <TextField
                  className="textfield"
                  label="Full Name"
                  name="full_name"
                  value={formData.full_name}
                  onChange={handleChange}
                  margin="normal"
                />
                <TextField
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                />
                {/* <TextField
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={handleChange}
                className="textfield"
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              /> */}
                <TextField
                  label="Services ID (comma separated)"
                  name="services_ID"
                  value={formData.services_ID}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                />
                <TextField
                  label="Phone Number"
                  name="phone_No"
                  value={formData.phone_No}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                />
              </div>
              <div>
                <TextField
                  label="Status"
                  name="status"
                  value={formData.status}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                />
                <TextField
                  label="Work Start Time"
                  name="workStartTime"
                  type="time"
                  value={formData.workStartTime}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  label="Work End Time"
                  name="workEndTime"
                  type="time"
                  value={formData.workEndTime}
                  onChange={handleChange}
                  className="textfield"
                  margin="normal"
                  InputLabelProps={{ shrink: true }}
                />

                
              </div>
              <Button
                  className="button-save"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
            </form>
          ) : (
            <p>Loading...</p>
          )}
          {alert.open && (
            <Alert
              severity={alert.severity}
              onClose={() => setAlert({ ...alert, open: false })}
            >
              {alert.message}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditEmployeePage;
