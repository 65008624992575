import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/sidebar/Sidebar';
import Navbar from '../../components/navbar/Navbar';
import { Visibility, Edit, Delete } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField, MenuItem } from '@mui/material';
import './Price.scss';

const initialServices = [
  { id: 1, category: "Hair", name: "Haircut", description: "Trim and style your hair for a fresh look.", duration: "30 minutes", price: "$30" },
  { id: 2, category: "Hair", name: "Hair Coloring", description: "Change or enhance your hair color with professional coloring services.", duration: "1-2 hours", price: "$50 - $100" },
  { id: 3, category: "Hair", name: "Hair Styling", description: "Elevate your look with expert hair styling techniques.", duration: "Varies", price: "$40 - $60" },
  { id: 4, category: "Nails", name: "Manicure", description: "Groom your nails and hands for a polished appearance.", duration: "45 minutes", price: "$25" },
  { id: 5, category: "Nails", name: "Pedicure", description: "Pamper your feet with a relaxing pedicure treatment.", duration: "1 hour", price: "$35" },
  { id: 6, category: "Pedique", name: "Foot Massage", description: "Relax and rejuvenate your feet with a soothing foot massage.", duration: "30 minutes", price: "$20" },
  { id: 7, category: "Facial", name: "Basic Facial", description: "Cleanse, exfoliate, and moisturize your skin for a refreshed appearance.", duration: "1 hour", price: "$50" },
  { id: 8, category: "Eyebrows", name: "Eyebrow Shaping", description: "Define and shape your eyebrows for a polished look.", duration: "15 minutes", price: "$15" },
  { id: 9, category: "Piercing", name: "Ear Piercing", description: "Get your ears pierced with precision and care.", duration: "Varies", price: "$20 - $50" },
];

const categories = ["Hair", "Nails", "Pedique", "Facial", "Eyebrows", "Piercing"];

const Price = () => {
  const [services, setServices] = useState(() => {
    const savedServices = localStorage.getItem('services');
    return savedServices ? JSON.parse(savedServices) : initialServices;
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [newService, setNewService] = useState({ category: "", name: "", description: "", duration: "", price: "" });

  useEffect(() => {
    localStorage.setItem('services', JSON.stringify(services));
  }, [services]);

  const handleEdit = (id) => {
    const serviceToEdit = services.find(service => service.id === id);
    const newDescription = prompt("Enter new description:", serviceToEdit.description);
    if (newDescription) {
      setServices(services.map(service => service.id === id ? { ...service, description: newDescription } : service));
    }
  };

  const handleDelete = (id) => {
    setOpenDeleteDialog(true);
    setServiceToDelete(id);
  };

  const confirmDelete = () => {
    setServices(services.filter(service => service.id !== serviceToDelete));
    setOpenDeleteDialog(false);
    setServiceToDelete(null);
  };

  const handleCloseDelete = () => {
    setOpenDeleteDialog(false);
    setServiceToDelete(null);
  };

  const handleOpenAddDialog = () => {
    setOpenAddDialog(true);
  };

  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setNewService({ category: "", name: "", description: "", duration: "", price: "" });
  };

  const handleAddService = () => {
    const id = services.length > 0 ? services[services.length - 1].id + 1 : 1;
    setServices([...services, { ...newService, id }]);
    handleCloseAddDialog();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewService({ ...newService, [name]: value });
  };

  const handleView = (id) => {
    const service = services.find(service => service.id === id);
    setSelectedService(service);
    setOpenViewDialog(true);
  };

  const handleCloseViewDialog = () => {
    setOpenViewDialog(false);
    setSelectedService(null);
  };

  return (
    <div className="price-page">
      <Sidebar />
      <div className="price-page-container">
        <Navbar />
        <div className="price-page-content">
          <h1>Price List</h1>
          <Button className="add-new-price-button" variant="contained" color="primary" onClick={handleOpenAddDialog}>Add New Price</Button>
          <div className="price-table">
            <table>
              <thead>
                <tr>
                  <th>Service</th>
                  <th>Description</th>
                  <th>Duration</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {categories.map(category => (
                  <React.Fragment key={category}>
                    <tr>
                      <td colSpan="5" className="category-header">{category}</td>
                    </tr>
                    {services.filter(service => service.category === category).map(service => (
                      <tr key={service.id}>
                        <td>{service.name}</td>
                        <td>{service.description}</td>
                        <td>{service.duration}</td>
                        <td>{service.price}</td>
                        <td>
                          <button onClick={() => handleView(service.id)}><Visibility /></button>
                          <button onClick={() => handleEdit(service.id)}><Edit /></button>
                          <button onClick={() => handleDelete(service.id)}><Delete /></button>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <br />
            <br />
            <div className="photo-chain-container">
              <img src="https://www.swagmee.com/media/wysiwyg/blog/safty..jpg" alt="Photo" />
              <img src="https://franchiseindia.s3.ap-south-1.amazonaws.com/uploads/content/wi/art/5cbdb26663682.jpeg" alt="Photo" />
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkYNvbCPf0r70oeiWDSDitBvW_0E4Q_x_aDtdna6YqfZtnwM_9JgrAv9o6_hHAV9KlGqQ&usqp=CAU" alt="Photo" />
              <img src="https://imgmedia.lbb.in/media/2020/02/5e4d51ba6e343b1b20e148ec_1582125498852.jpg" alt="Photo" />
            </div>
          </div>
        </div>
      </div>

      <Dialog open={openDeleteDialog} onClose={handleCloseDelete}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this service?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">Cancel</Button>
          <Button onClick={confirmDelete} color="primary">Delete</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
        <DialogTitle>Add New Service</DialogTitle>
        <DialogContent className="dialog-content">
          <TextField
            select
            margin="dense"
            name="category"
            label="Category"
            fullWidth
            value={newService.category}
            onChange={handleChange}
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            name="name"
            label="Service Name"
            fullWidth
            value={newService.name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="description"
            label="Description"
            fullWidth
            value={newService.description}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="duration"
            label="Duration"
            fullWidth
            value={newService.duration}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="price"
            label="Price"
            fullWidth
            value={newService.price}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddDialog} color="primary">Cancel</Button>
          <Button onClick={handleAddService} color="primary">Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openViewDialog} onClose={handleCloseViewDialog}>
        <DialogTitle>Service Details</DialogTitle>
        <DialogContent className="view-dialog-content">
          {selectedService && (
            <>
              <DialogContentText><strong>Category:</strong> {selectedService.category}</DialogContentText>
              <DialogContentText><strong>Service:</strong> {selectedService.name}</DialogContentText>
              <DialogContentText><strong>Description:</strong> {selectedService.description}</DialogContentText>
              <DialogContentText><strong>Duration:</strong> {selectedService.duration}</DialogContentText>
              <DialogContentText><strong>Price:</strong> {selectedService.price}</DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseViewDialog} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Price;
