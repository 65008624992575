

import React from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/Navbar';
import './CancelledAppointments.scss';

function CancelledAppointments() {
  const cancelledAppointments = [
    {
      id: 3,
      clientName: 'Mike Johnson',
      contactNumber: '789-012-3456',
      service: 'Nail Art',
      employee: 'Sophia',
      date: new Date('2024-04-26T13:00:00Z'),
      paymentStatus: 'Pending',
      cancelReason: 'Emergency appointment',
    },
    {
      id: 6,
      clientName: 'Sarah Davis',
      contactNumber: '987-012-3456',
      service: 'Manicure',
      employee: 'Lisa',
      date: new Date('2024-04-29T11:00:00Z'),
      paymentStatus: 'Pending',
      cancelReason: 'Personal reasons',
    },
    {
      id: 9,
      clientName: 'Isabella Garcia',
      contactNumber: '777-888-9999',
      service: 'Facial',
      employee: 'Emily',
      date: new Date('2024-05-03T10:00:00Z'),
      paymentStatus: 'Pending',
      cancelReason: 'Sudden illness',
    },
    {
      id: 12,
      clientName: 'James Brown',
      contactNumber: '555-444-3333',
      service: 'Manicure',
      employee: 'Lisa',
      date: new Date('2024-05-06T09:00:00Z'),
      paymentStatus: 'Pending',
      cancelReason: 'Unforeseen circumstances',
    },
   
  ];

  // Function to format date in a readable format
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  // Function to format time from the Date object
  const formatTime = (date) => {
    const options = { hour: 'numeric', minute: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date);
  };

  return (
    <div className="cancelled-appointments">
      <Sidebar />
      <div className="cancelled-appointments-container">
        <Navbar />
        <div className="cancelled-appointments-top">
          <h3>Cancelled Appointments</h3>
          <br></br>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Client Name</th>
                <th>Contact Number</th>
                <th>Service</th>
                <th>Employee</th>
                <th>Date</th>
                <th>Time</th>
                <th>Reason for Cancelling</th>
              </tr>
            </thead>
            <tbody>
              {cancelledAppointments.map(appointment => (
                <tr key={appointment.id}>
                  <td>{appointment.id}</td>
                  <td>{appointment.clientName}</td>
                  <td>{appointment.contactNumber}</td>
                  <td>{appointment.service}</td>
                  <td>{appointment.employee}</td>
                  <td>{formatDate(appointment.date)}</td> {/* Format date */}
                  <td>{formatTime(appointment.date)}</td> {/* Format time */}
                  <td>{appointment.cancelReason}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Link to="/appointments">Back to Appointments</Link>
        </div>
      </div>
    </div>
  );
}

export default CancelledAppointments;
