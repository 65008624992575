import React, { useState , useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import './AddCategoryForm.scss';


const AddCategoryForm = ({ title }) => {
  const [file, setFile] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [timeDuration, setTimeDuration] = useState("");
  const [review, setReview] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {///////////////////////////
    if (!token) {
      navigate("/");
      return;
    }
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any required field is empty
    if (!categoryName || !description || !timeDuration || !review) {
      setErrorMessage('Please fill in all the required fields.');
      return;
    }

    try {
      // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoyLCJyb2xlIjoiYWRtaW4iLCJpYXQiOjE3MTYxOTgwMjEsImV4cCI6MTcxNjIwMTYyMX0.rsENFqAyH9diNehfYOTzXXQVWmqWjB-ee0HYUZer1oI"; // Replace  with your actual authorization token
      const requestBody = {
        serviceName: categoryName,
        description: description,
        timeDuration: timeDuration,
        review: review
      };

      const response = await fetch('https://salon.hasthiya.org/service/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Include the authorization token in the request headers
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(`Network response was not ok: ${errorMessage}`);
      }

      const responseData = await response.json();
      console.log('Category added successfully:', responseData);
      
      // Reset form fields after successful submission
      setFile('');
      setCategoryName('');
      setDescription('');
      setTimeDuration('');
      setReview('');
      setSuccessMessage('Category added successfully');

      // Clear success message after a few seconds
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000); // 3 seconds

      // Clear error message
      setErrorMessage('');

      // Reload the page to reflect the changes
      window.location.reload();
    } catch (error) {
      console.error('Error adding category:', error.message);
    }
  };

  return (
    <div className="new">
      <div className="newContainer">
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{ display: "none" }}
                />
              </div>

              <div className="formInput">
                <label>Category Name:</label>
                <input
                  type="text"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  placeholder="Enter category name"
                />
              </div>

              <div className="formInput">
                <label>Description:</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Enter description"
                ></textarea>
              </div>

              <div className="formInput">
                <label>Time Duration:</label>
                <input
                  type="text"
                  value={timeDuration}
                  onChange={(e) => setTimeDuration(e.target.value)}
                  placeholder="Enter time duration"
                />
              </div>

              <div className="formInput">
                <label>Review:</label>
                <input
                  type="text"
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                  placeholder="Enter review"
                />
              </div>

              <button type="submit">Add Category</button>
              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
              {successMessage && <p>{successMessage}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCategoryForm;
