import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

const Datatable = () => {
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
  const token = sessionStorage.getItem("token"); //session part
  const navigate = useNavigate();///////////////////////////

  useEffect(() => {///////////////////////////
    if (!token) {
      navigate("/");
      return;
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://salon.hasthiya.org/employee/getAll",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, //session part
            },
          }
        );

        if (response.status === 401) {     ////////////////
          navigate("/");
          return;
        }
        if (!response.ok) {
          throw new Error("Failed to fetch employee data");
        }

        const responseData = await response.json();
        if (responseData.status) {
          setEmployees(responseData.data.data);
        } else {
          throw new Error(responseData.message);
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(
        `https://salon.hasthiya.org/employee/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        setEmployees((prevEmployees) =>
          prevEmployees.filter((employee) => employee.emp_id !== id)
        );
        setSnackbarMessage("Employee deleted successfully");
        setSnackbarSeverity("success");
      } else {
        const responseBody = await response.text();
        console.error(`Failed to delete employee: ${responseBody}`);
        setSnackbarMessage("Failed to delete employee");
        setSnackbarSeverity("error");
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
      setSnackbarMessage("Error deleting employee");
      setSnackbarSeverity("error");
    } finally {
      setSnackbarOpen(true);
      setDialogOpen(false);
    }
  };

  const handleDialogOpen = (id) => {
    setSelectedEmployeeId(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = [
    { field: "emp_id", headerName: "ID", width: 50 },
    { field: "full_name", headerName: "Full Name", width: 180 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "status", headerName: "Status", width: 100 },
    { field: "workStartTime", headerName: "Start Time", width: 170 },
    { field: "workEndTime", headerName: "End Time", width: 170 },
    // Add more columns as needed
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={`/users/${params.row.emp_id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link>

            <div className="deleteButton" onClick={() => handleDialogOpen(params.row.emp_id)}>
              Delete
            </div>
          </div>
        );
        
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Add New Employee
        <Link to="/users/new" className="link">
          Add New
        </Link>
      </div>
      {error && <p>{error}</p>}
      <DataGrid
        className="datagrid"
        rows={employees}
        columns={columns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        getRowId={(row) => row.emp_id}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={dialogOpen} onClose={handleDialogClose} maxWidth="xs">
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this employee? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDelete(selectedEmployeeId)}
            color="secondary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Datatable;
