import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './login.css'; 

function LoginPage() {
    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loginError, setLoginError] = useState(null);


    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: ''
      })

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({
            ...formData, [name] : value
        })
      }

    const handleRegisterClick = () => {
        setIsActive(true);
    };

    const handleLoginClick = () => {
        setIsActive(true);
    };

    const handleRegister = async (e) => {
        e.preventDefault()
        const validationErrors = {}
        if(!formData.name.trim()) {
            validationErrors.name = "username is required"
        }
    
        if(!formData.email.trim()) {
            validationErrors.email = "email is required"
        } else if(!/\S+@\S+\.\S+/.test(formData.email)){
            validationErrors.email = "email is not valid"
        }
    
        if(!formData.password.trim()) {
            validationErrors.password = "password is required"
        } else if(formData.password.length < 6){
            validationErrors.password = "password should be at least 6 char"
        }
    
        setErrors(validationErrors)
    
        if(Object.keys(validationErrors).length === 0) {
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        user_name: formData.name,
                        email: formData.email,
                        password: formData.password,
                        role: 'admin' // Assuming all registrations through this form are for admin role
                    })
                };

                const response = await fetch('https://salon.hasthiya.org/user/register', requestOptions);
                const data = await response.json();
                
                if (response.ok) {
                    console.log('Registration successful:', data.result.message);
                    
                    sessionStorage.setItem('token', data.result.token);
                    navigate('./dashboard');
                }else {
                    if (data.result && data.result.message === "user already exists") {
                        alert("Registration failed: User already exists");
                    } else {
                        alert('Registration failed:', data.result.message);
                    }
                }
            }catch (error) {
                alert('Registration error:');
                console.error('Registration error:', error);
            }
        }
    
      }
    

    const handleLogin = async (event) => {
        event.preventDefault();

        if (!email.trim() || !password.trim()) {
            setLoginError("Please provide both email and password.");
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email, password: password })
        };

        try {
            const response = await fetch('https://salon.hasthiya.org/user/login', requestOptions);
            const data = await response.json();
            if (response.ok) {
                console.log('Login successful:', data.result.message);
                sessionStorage.setItem('token', data.result.token);
                navigate('./dashboard');
            } else {
                setLoginError("Please provide valid creadential.");
                console.log('Login failed:', data.result.message);
            }
        } catch (error) {
            setLoginError("Please provide valid creadentials.");
            console.error('Login error:', error);
        }
    };


    return (
        <div className="lg-body">
        <div className={`container ${isActive ? 'active' : ''}`} id="container">
            <div className="form-container sign-up">
            <form onSubmit={handleRegister}>
                    <h1>Create Account</h1>
                    <div>
                       <input type="email" name='email' placeholder="Email" onChange={handleChange}   />
                       {errors.email && <span>{errors.email}</span>} 
                    </div>
                    <div>
                        <input type="password" name='password' placeholder="Password" onChange={handleChange}   />
                        {errors.password && <span>{errors.password}</span>} 
                    </div>
                    <div>
                       <input type="text" name='name' placeholder="Name" onChange={handleChange}   /> 
                       {errors.name && <span>{errors.name}</span>}  
                    </div>
                    
                    
                    
                    <button type="submit">Sign Up</button>
                </form>
            </div>
            <div className="form-container sign-in">
            <form onSubmit={handleLogin}>
                    <h1>Sign In</h1>
                    {loginError && <span>{loginError}</span>}
                    <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} />
                    <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                    <button type="submit">Sign In</button>
                </form>
            </div>
            <div className="toggle-container">
                <div className="toggle">
                    <div className="toggle-panel toggle-left">
                        <h1>Welcome Back!</h1>
                        <p>Access your admin panel to manage settings and monitor activity.</p>
                        <button className="hidden" onClick={handleLoginClick} id="login">Sign In</button>
                    </div>
                    <div className="toggle-panel toggle-right">
                        <h1>Hello, Admin!</h1>
                        <p>Sign up to manage, monitor, and configure settings in the admin panel.</p>
                        <button className="hidden" onClick={handleRegisterClick} id="register">Sign Up</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

export default LoginPage;
